import { initializeDynamicImports } from './dynamicImports';
import { SASReportElement } from './SASReportElement';
import { SASReportObjectElement } from './SASReportObjectElement';
import { SASReportPageElement } from './SASReportPageElement';

export { SASReportElement, SASReportObjectElement, SASReportPageElement };

export function defineCustomElements(
  dynamicImportConfig?: Parameters<typeof initializeDynamicImports>[0]
) {
  initializeDynamicImports(dynamicImportConfig);
  if (!customElements.get('sas-report')) {
    customElements.define('sas-report', SASReportElement);
  }
  if (!customElements.get('sas-report-object')) {
    customElements.define('sas-report-object', SASReportObjectElement);
  }
  if (!customElements.get('sas-report-page')) {
    customElements.define('sas-report-page', SASReportPageElement);
  }
}
