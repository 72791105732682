import type { AbstractReportProps } from '../components/SASReport';
import type { MenuItemProvider } from '@sas-dvr/internal-va-react-core/components/ReportContainer/MenuItemProvider';
import type { ReportHandle } from '../handles';

import { createUniqueId } from '@sas-dvr/internal-va-react-core/components/utils/createUniqueId';
import { ReactCustomElement } from './ReactCustomElement';
import {
  subscribeImportsLoaded,
  unsubscribeImportsLoaded,
  getImports,
  DynamicImportsType,
} from './dynamicImports';

function getAuthValue(value?: string | null) {
  if (!value) {
    return;
  }
  switch (value.toLowerCase()) {
    case 'guest':
      return 'guest';
    case 'credentials':
      return 'credentials';
  }
}

/**
 * @public
 */
export abstract class AbstractReportElement<
  T extends {
    /** @internal */
    props: AbstractReportProps;
  }
> extends ReactCustomElement<T> {
  /**
   * A unique identifier tied to the lifetime of the custom element.
   * Used to pair report elements with their context and store.
   * @internal
   */
  private _elementKey = createUniqueId();

  /**
   * @internal
   */
  private _stateRetained = false;

  /**
   * @internal
   */
  private _initialized = false;

  /**
   * @internal
   */
  private _menuItemProvider?: MenuItemProvider;

  /**
   * @internal
   */
  private _contextKey?: string;

  /**
   * Whether the current value of this._handle can be safely given to consumers.
   * A handle is only valid from the time React renders the component until
   * props are changed or the element is removed from the DOM with
   * preserveStateOnUnmount set to false.
   * @internal
   */
  private _refHandle?: (ref: ReportHandle | null) => void;
  /**
   * @internal
   */
  private _isHandleValid: boolean = false;
  /**
   * @internal
   */
  private _handle?: ReportHandle | null;
  /**
   * @internal
   */
  private _handlePromise?: Promise<ReportHandle>;
  /**
   * @internal
   */
  private _handlePromiseCallbacks?: {
    accept: (handle: ReportHandle) => void;
    reject: (reason: string) => void;
  };

  /**
   * @internal
   */
  private _acceptHandlePromise() {
    this._isHandleValid = true;
    if (this._handle) {
      this._handlePromiseCallbacks?.accept(this._handle);
      this._handlePromiseCallbacks = undefined;
    } else {
      console.warn('_acceptHandlePromise called when no report handle was available');
    }
  }

  /**
   * @internal
   */
  protected _invalidateHandleRequests(reason: string) {
    // Invalidate report handle.
    // Don't discard this._handle because React might not update the ref in the next render.
    this._isHandleValid = false;
    this._handlePromiseCallbacks?.reject(reason);
    this._handlePromiseCallbacks = undefined;
    this._handlePromise = undefined;
  }

  // Note: the user must call this again if they change any property on the custom element
  getReportHandle(): Promise<ReportHandle> {
    if (!this._handlePromise) {
      // If we have a valid handle, resolve immediately.
      // Otherwise wait for the ref to be set during the next render.
      this._handlePromise =
        this._handle && this._isHandleValid
          ? Promise.resolve(this._handle)
          : new Promise((accept, reject) => {
              this._handlePromiseCallbacks = { accept, reject };
            });
    }
    return this._handlePromise;
  }

  /**
   * @internal
   */
  protected _getContextKey(props: T['props']): string | undefined {
    return 'url' in props
      ? `${props.url}:${props.reportUri}:${this._elementKey}`
      : `${props.packageUri}:${this._elementKey}`;
  }

  /**
   * @internal
   */
  protected _invalidateProps() {
    let newKey = undefined;
    const props = this.getRenderProps();
    if (props) {
      newKey = this._getContextKey(props);
    }

    // NOTE: if this function changes, _initializeWithImports may need to be update
    if (this._contextKey !== newKey) {
      this._refHandle = undefined;
      this._invalidateHandleRequests(
        'An element attribute was changed and the handle request has been cancelled'
      );
    }
    this._contextKey = newKey;
    super._invalidateProps();
  }

  /**
   * @internal
   */
  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      'url',
      'reporturi',
      'authenticationtype',
      'packageuri',
      'popoverrootid',
      'restrictviewportgestures',
    ];
  }

  /**
   * @internal
   */
  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);
    if (
      name === 'authenticationType' &&
      this.hasAttribute('authenticationType') &&
      !getAuthValue(value)
    ) {
      console.warn(`Invalid AuthenticationType: ${value}`);
    }
  }

  /**
   * @internal
   */
  connectedCallback() {
    // Calling _initializeWithImports should "replay" the current state of the WebComponent and pass it to the
    // underlying implementation that gets async loaded.
    subscribeImportsLoaded(this, () => {
      if (this._initialized) {
        return;
      }
      this._initialized = true;
      this._initializeWithImports();
    });
    super.connectedCallback();
  }

  /**
   * @internal
   */
  disconnectedCallback() {
    if (!this.preserveStateOnUnmount) {
      // Reject only if preserveStateOnUnmount is false.
      // In OpenUI, the element is briefly disconnected during a rerender. No need to reject in that case.
      this._invalidateHandleRequests(
        'The element was removed from the DOM and the handle request has been cancelled'
      );
    }
    if (this._stateRetained) {
      unsubscribeImportsLoaded(this);
    }
    super.disconnectedCallback();
  }

  /**
   * @internal
   */
  protected _initializeWithImports() {
    // Using super instead of this because we don't want to reject ReportHandle
    //  requests that may have been made before the imports were available
    super._invalidateProps();

    const state = this._stateRetained;
    this._stateRetained = false;
    this.preserveStateOnUnmount = state;
  }

  /**
   * A boolean property for controlling the lifetime of state used by this
   * custom element. If false, state will be lost when the element is removed
   * from the DOM. If true, state will be preserved until the property is
   * set back to false.
   *
   * If set to true, the property must be set back to false prior to garbage
   * collection or a large memory leak will occur.
   * @internal
   */
  get preserveStateOnUnmount() {
    return this._stateRetained;
  }

  set preserveStateOnUnmount(value: boolean) {
    if (this._stateRetained === !!value) {
      return;
    }
    if (!this._stateRetained && !this._getConnected()) {
      unsubscribeImportsLoaded(this);
    }
    const { extendStoreLifetime, releaseStoreLifetime } = this._getDynamicImports() ?? {};
    this._stateRetained = !!value;
    if (this._stateRetained) {
      extendStoreLifetime?.(this._elementKey);
    } else {
      releaseStoreLifetime?.(this._elementKey);
      if (!this._getConnected()) {
        this._invalidateHandleRequests(
          'preserveStateOnUnmount was set to false while the element was removed from the DOM'
        );
      }
    }
  }

  /**
   * @internal
   */
  private _getDynamicImports(): (DynamicImportsType & { type: 'dynamic' }) | undefined {
    const imports = getImports();
    if (imports?.type === 'dynamic') {
      return imports;
    }
    return;
  }

  /**
   * @internal
   */
  protected getCommonProps(): AbstractReportProps | null {
    if (!this._refHandle) {
      this._refHandle = (ref) => this._setRef(ref);
    }
    const props = {
      elementKey: this._elementKey,
      ref: this._refHandle,
      menuItemProvider: this._menuItemProvider,
      popoverRootId: this.popoverRootId ?? undefined,
      restrictViewportGestures: this.restrictViewportGestures,
    };
    if (this.reportUri && this.url) {
      return {
        ...props,
        authenticationType: this.authenticationType,
        reportUri: this.reportUri,
        url: this.url,
      };
    } else if (this.packageUri) {
      return {
        ...props,
        packageUri: this.packageUri,
      };
    }
    return null;
  }

  /**
   * @internal
   */
  private _setRef = (ref: ReportHandle | null) => {
    this._handle = ref;
    if (ref) {
      this._acceptHandlePromise();
    }
  };

  get url() {
    return this.getAttribute('url');
  }

  set url(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('url', value);
    } else {
      this.removeAttribute('url');
    }
  }

  get packageUri() {
    return this.getAttribute('packageUri');
  }

  set packageUri(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('packageUri', value);
    } else {
      this.removeAttribute('packageUri');
    }
  }

  get reportUri() {
    return this.getAttribute('reportUri');
  }

  set reportUri(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('reportUri', value);
    } else {
      this.removeAttribute('reportUri');
    }
  }

  get authenticationType(): 'guest' | 'credentials' {
    return getAuthValue(this.getAttribute('authenticationType')) || 'credentials';
  }

  set authenticationType(value) {
    if (value) {
      if (typeof value !== 'string' || !getAuthValue(value)) {
        console.warn(`Invalid AuthenticationType: ${value}`);
        this.removeAttribute('authenticationType');
      } else {
        this.setAttribute('authenticationType', value.toLowerCase());
      }
    } else {
      this.removeAttribute('authenticationType');
    }
  }

  /**
   * @internal
   */
  get popoverRootId() {
    return this.getAttribute('popoverRootId');
  }

  set popoverRootId(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('popoverRootId', value);
    } else {
      this.removeAttribute('popoverRootId');
    }
  }

  get restrictViewportGestures(): boolean | undefined {
    const value = this.getAttribute('restrictViewportGestures');
    if (!value) {
      return undefined;
    }
    const lower = value.toLowerCase();
    if (lower === 'true') {
      return true;
    } else if (lower === 'false') {
      return false;
    }

    return undefined;
  }

  set restrictViewportGestures(value) {
    if (typeof value === 'boolean') {
      this.setAttribute('restrictViewportGestures', value.toString());
      return;
    } else if (!value) {
      this.removeAttribute('restrictViewportGestures');
    } else {
      console.warn(`Invalid value for restrictViewportGestures: ${value}`);
      this.removeAttribute('restrictViewportGestures');
    }
  }

  /**
   * Creates a new key for use in the next render. Used in SASReportElement to
   * force a context to load with new graph css.
   * @internal
   */
  protected _resetElementKey() {
    this._invalidateProps();

    const oldKey = this._elementKey;
    this._elementKey = createUniqueId();

    if (this._stateRetained) {
      const imports = this._getDynamicImports();
      imports?.extendStoreLifetime?.(this._elementKey);
      setTimeout(() => imports?.releaseStoreLifetime?.(oldKey), 0);
    }
  }

  set menuItemProvider(value) {
    value = value ?? undefined;
    if (value === this._menuItemProvider) {
      return;
    }
    this._invalidateProps();
    this._menuItemProvider = value;
  }

  get menuItemProvider() {
    return this._menuItemProvider;
  }
}
