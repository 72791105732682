import { AbstractReportElement } from './AbstractReportElement';
import { getImports } from './dynamicImports';

// NOTE: only types here
import type { ReportBaseThemeId, ThemeParameterOverrides } from '@sas/nova-commons/design-tokens';
import type { SASReportProps } from '../components/SASReport';

function validateHideNavigationValue(value?: null | boolean | string) {
  if (value && typeof value === 'string') {
    const lower = value.toLocaleLowerCase();
    return ['true', 'false', 'auto'].indexOf(lower) > -1;
  }
  return true;
}

/**
 * @public @sealed
 */
export class SASReportElement extends AbstractReportElement<{
  /** @internal */
  props: SASReportProps;
}> {
  /**
   * @internal
   */
  private _graphCSS: string | undefined;
  /**
   * @internal
   */
  private _themeOverrides: ThemeParameterOverrides | null = null;
  /**
   * @internal
   */
  private _unprocessedOverrides: unknown;

  /**
   * @internal
   */
  protected _getRenderer() {
    return getImports()?.SASReport;
  }

  /**
   * @internal
   */
  static get observedAttributes() {
    return [...super.observedAttributes, 'hidenavigation', 'parameters'];
  }

  attributeChangedCallback(name: string, old: string | null, value: string | null) {
    super.attributeChangedCallback(name, old, value);
    if (
      name === 'hideNavigation' &&
      this.hasAttribute('hideNavigation') &&
      !validateHideNavigationValue(value)
    ) {
      console.warn(`Invalid value for hideNavigation: ${value}`);
    }
  }

  /**
   * @internal
   */
  protected _initializeWithImports() {
    super._initializeWithImports();
    if (this._unprocessedOverrides) {
      this.unsafe_setCustomReportTheme(this._unprocessedOverrides as any);
      this._unprocessedOverrides = undefined;
    }
  }

  /**
   * @internal
   */
  protected getRenderProps(): SASReportProps | null {
    const commonProps = this.getCommonProps();
    return (
      commonProps && {
        ...commonProps,
        hideNavigation: this.hideNavigation,
        themeOverrides: this._themeOverrides || undefined,
        graphCSS: this._graphCSS,
      }
    );
  }

  get hideNavigation(): boolean | 'auto' {
    if (!this.hasAttribute('hideNavigation')) return 'auto';

    const value = this.getAttribute('hideNavigation');
    if (typeof value === 'string') {
      switch (value.toLocaleLowerCase()) {
        case 'true':
        case '':
          return true;
        case 'false':
          return false;
        case 'auto':
        default:
          return 'auto';
      }
    }
    return true;
  }

  set hideNavigation(value) {
    if (typeof value === 'string') {
      if (!validateHideNavigationValue(value)) {
        console.warn(`Invalid value for hideNavigation: ${value}`);
        this.removeAttribute('hideNavigation');
      } else {
        this.setAttribute('hideNavigation', value);
      }
    } else if (typeof value === 'boolean') {
      this.setAttribute('hideNavigation', value.toString());
      return;
    } else if (!value) {
      this.removeAttribute('hideNavigation');
    } else {
      console.warn(`Invalid value for hideNavigation: ${value}`);
      this.removeAttribute('hideNavigation');
    }
  }

  /**
   * Sets report theme overrides using the data format used by the SAS theme service.
   * This function is intended for use by ThemeDesigner only.
   * @internal
   */
  unsafe_setCustomReportTheme(themeOverride: ThemeParameterOverrides | null | undefined): void;
  unsafe_setCustomReportTheme(value: unknown) {
    const imports = getImports();
    if (!imports) {
      this._unprocessedOverrides = value;
      return;
    }
    if (imports.type === 'mobile') {
      console.error('customReportThemes not supported in this render mode.');
      return;
    }
    let overrides;
    if (typeof value === 'object' && value !== null) {
      const objectValue = value as { [index: string]: unknown };
      const themeOverride = { baseThemeId: 'light' as ReportBaseThemeId, styleData: {} };
      if (typeof objectValue.baseThemeId === 'string') {
        themeOverride.baseThemeId = objectValue.baseThemeId as ReportBaseThemeId;
      } else {
        console.error(
          'Objects passed to unsafe_setCustomReportTheme must have a string for the baseThemeId property'
        );
      }
      if (typeof objectValue.styleData === 'object' && objectValue.styleData !== null) {
        themeOverride.styleData = objectValue.styleData;
      } else {
        console.error(
          'Objects passed to unsafe_setCustomReportTheme must have an object for the styleData property'
        );
      }
      overrides = imports.convertThemeServiceResponseToThemeParameterOverrides(themeOverride);
    } else if (value === null || value === undefined) {
      overrides = null;
    } else {
      overrides = null;
      console.error('Invalid value passed to unsafe_setCustomReportTheme: ' + value);
    }
    if (this._themeOverrides === overrides) {
      return;
    }
    this._themeOverrides = overrides;
    this._invalidateProps();
  }

  /**
   * This function's intended use is to force graph CSS to reload when customizing themes in ThemeDesigner.
   * Ideally, graph CSS would be modified through themeOverrides, but portable does not yet support that.
   * @internal
   */
  unsafe_setGraphCSS(graphCSS: string | null | undefined): void;
  unsafe_setGraphCSS(value: unknown) {
    let graphCSS;
    if (typeof value === 'string') {
      graphCSS = value;
    } else if (value === null || value === undefined) {
      graphCSS = undefined;
    } else {
      graphCSS = undefined;
      console.error('Invalid value passed to unsafe_setGraphCSS: ' + value);
    }
    if (this._graphCSS === graphCSS) {
      return;
    }
    this._graphCSS = graphCSS;

    // Force a new report context to be created so the css gets applied
    this._resetElementKey();
    this._invalidateProps();
  }
}
