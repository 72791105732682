import { publicPath } from './init/fixWebpackPublicPath';
import { loadScript } from '@sas-dvr/ltjs-commons/util/ltjsLoaderUtil';
import { ltjsGlobalNamespace } from '@sas-dvr/ltjs-commons/ltjsInit';
import { defineCustomElements } from './elements';
import { displayImagesForMobile } from './elements/dynamicImports/isMobile';
import { fireLoadedEvent } from './init/loadedEvent';
import { whenCssLoaded } from './init/loadCss';
import type * as vaReportComponents from './exports/vaReportComponents';

import '@sas/nova-commons/embedded.css';
import './loader.css';

// Prevent nova-core from making calls to Viya
import { initConfiguration } from '@sas/nova-core/config';
initConfiguration({ disableConfigurationEndPoint: true });
(window as any).sas.axiosPreventFetchAcceptLocale = true;

declare const ROOT_CLASS_NAME: string;
declare global {
  interface Window {
    vaReportComponents: typeof vaReportComponents | undefined;
  }
}

const mobileImages = displayImagesForMobile();
if (!mobileImages) {
  //
  // Load LTJS
  // Set the ltjslibpath relative to this loader script.
  //
  ltjsGlobalNamespace.resourceUrl = publicPath + 'assets/';
  // va-sdk UMD builds exclude ICU assets, so always use browser collation
  ltjsGlobalNamespace.useBrowserCollation = true;
  loadScript([]);
}
defineCustomElements({
  importType: mobileImages ? 'mobile' : 'dynamic',
  async initialize(imports) {
    if (imports.type === 'dynamic') {
      imports.setupLtjsRuntime();
      imports.setupLtjsEnvironment();
      imports.initRootStyles(ROOT_CLASS_NAME);
      imports.setHonorLocalFormatSettings(true);
      imports.initNovaI18n();

      await whenCssLoaded();
      window.vaReportComponents = imports.vaReportComponents;
      fireLoadedEvent();
    }
  },
});
